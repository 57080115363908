import React from 'react';
import { PathService } from '../../services';
import { IndiaPostViewContext, TemplateProps } from '../../types';
import { IndiaPostView } from '../../views';

const IndiaPost: React.FunctionComponent<TemplateProps<
  IndiaPostViewContext
>> = ({ location, pageContext }) => {
  PathService.set(location.pathname);
  return (
    <IndiaPostView
      page={JSON.parse(pageContext.page)}
      content={JSON.parse(pageContext.content)}
      sideNavItems={JSON.parse(pageContext.sideNavItems)}
    />
  );
};

export default IndiaPost;
